import React from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import * as S from "./IndexPage.styles.js";

import Header from "../Header/Header.jsx";
import Page from "../Page/Page.jsx";
import Project from "../Project/Project.jsx";
import Resume from "../Resume/Resume.jsx";
import Modal from "../Modal/Modal.jsx";

import useProjects from "../../common/useProjects.js";

import "./modal--resume.scss";

export default function IndexPage(props) {
  const location = useLocation();

  const isResumeOpen = location.hash === "#resume";

  const projects = useProjects();

  const onClose = () => {
    navigate("/");
  };

  return (
    <Page>
      <Header />

      {/* <div>
        {projects.map((project) => {
          const { date, title } = project;
          return (
            <div key={title}>
              {date} - {title}
            </div>
          );
        })}
      </div> */}

      <S.Projects>
        {projects.map((project, i) => {
          return (
            <Project key={project.title} isOdd={i % 2 !== 0} {...project} />
          );
        })}
      </S.Projects>

      <Modal
        className="modal--invisible modal--resume"
        isOpen={isResumeOpen}
        onClose={onClose}
      >
        <Resume onClose={onClose} />
      </Modal>

      <S.Footer>
        This site was{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-code"
          role="img"
        >
          <title>coded</title>
          <polyline points="16 18 22 12 16 6"></polyline>
          <polyline points="8 6 2 12 8 18"></polyline>
        </svg>{" "}
        with{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#e74c3c"
          stroke="#c0392b"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-heart"
          role="img"
        >
          <title>heart</title>
          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
        </svg>{" "}
        by Mike.
      </S.Footer>
    </Page>
  );
}
