import React from "react";
import * as S from "./Resume.styles.js";

import { func } from "prop-types";

const propTypes = {
  onClose: func.isRequired,
};

const Resume = (props) => {
  const { onClose } = props;

  return (
    <S.Paper>
      <S.Download>
        Download as{" "}
        <a href="./Mike Lambert Resume.pdf" target="_blank">
          .pdf
        </a>
        <S.Divider />
        <S.CloseButton onClick={onClose}>close</S.CloseButton>
      </S.Download>

      <S.Top>
        <S.Heading>Mike Lambert</S.Heading>
        <S.Contacts>
          <S.LinkAnchor href="mailto:michael.c.lambert@gmail.com">
            michael.c.lambert@gmail.com
          </S.LinkAnchor>{" "}
          |{" "}
          <S.LinkAnchor
            href="https://www.linkedin.com/in/lax4mike/"
            target="_blank"
          >
            linkedin.com/in/lax4mike
          </S.LinkAnchor>{" "}
          | <S.Phone>(602) 451-0571</S.Phone>
        </S.Contacts>
        <S.Blurb>
          I am a web developer who enjoys building awesome web interactions
          using my passion for computer science, functional programming, data
          visualizations and user experience design.
        </S.Blurb>
      </S.Top>

      <S.Section>
        <S.Heading>Skills</S.Heading>
        <S.Indent>
          <S.Skills>
            <S.Skill>
              Javascript{" "}
              <S.Sub>
                (React, D3, Ramda, Node, Jest, Typescript, Jamstack frameworks,
                etc),
              </S.Sub>
            </S.Skill>{" "}
            HTML,{" "}
            <S.Skill>
              CSS <S.Sub>(Sass, CSS-in-JS)</S.Sub>,
            </S.Skill>{" "}
            <S.Skill>
              Cloud services <S.Sub>(AWS, Azure),</S.Sub>
            </S.Skill>{" "}
            Git, Regex, a11y
          </S.Skills>
        </S.Indent>
      </S.Section>
      <S.Section>
        <S.Heading>Experience</S.Heading>

        <S.Work>
          <S.Title>
            <S.DateRange>2021-2022</S.DateRange>
            Vista
          </S.Title>
          <S.Position>Lead Software Engineer</S.Position>
          <S.Ul>
            <li>
              Architected a system and led a team to migrate hundreds of
              millions of documents to a new platform leveraging AWS for
              parallel computing.
            </li>
            <li>
              Contributed to an internal reusable React component library used
              to build interactive design experiences.
            </li>
            <li>
              Designed and led coding workshops to teach colleagues front end
              best practices.
            </li>
          </S.Ul>
        </S.Work>

        <S.Work>
          <S.Title>
            <S.DateRange>2014 - 2021</S.DateRange>
            Velir
          </S.Title>
          <S.Position>Principal Front-end Web Developer</S.Position>

          <S.Ul>
            <li>
              Developed rich web applications and data visualizations using D3
              and React.
            </li>
            <li>Wrote modular and reusable Javascript components.</li>
            <li>
              Led teams of developers and prioritized tasks to deliver high
              quality results within time and budget constraints.
            </li>
            <li>
              Fostered a collaborative environment by providing frequent ad-hoc
              mentorship.
            </li>
            <li>
              Developed and maintained a flexible custom build system for front
              end assets and coding standards used in projects across the
              company.
            </li>
          </S.Ul>
        </S.Work>

        <S.Work>
          <S.Title>
            <S.DateRange>2011 - 2014</S.DateRange>
            One Pica
          </S.Title>
          <S.Position>Web Developer / Solutions Architect</S.Position>

          <S.Ul>
            <li>Developed public facing e-commerce websites.</li>
            <li>Led a project team with frequent releases and deadlines.</li>
            <li>
              Wrote tech specs, interacted with clients, and organized tasks.
            </li>
          </S.Ul>
        </S.Work>
      </S.Section>

      <S.Section>
        <S.Heading>Education</S.Heading>

        <S.Indent>
          <S.Title>
            <S.DateRange>2007</S.DateRange>
            <span className="school">Northeastern University</span>{" "}
            <span className="location">| Boston, MA </span>
          </S.Title>

          <S.Ul>
            <li>
              Bachelor of Science in Computer Sciences and Multimedia
              (Dual-Major)
            </li>
            <li>Worked two 6-month interships for MIT and Harvard</li>
            {/* <li>GPA 3.265</li> */}
          </S.Ul>
        </S.Indent>
      </S.Section>

      <S.Interests>
        I enjoy rock climbing, sailing, skiing, scuba diving, guitar and piano.
      </S.Interests>
    </S.Paper>
  );
};

Resume.propTypes = propTypes;
export default Resume;
