import styled from "styled-components";
import { blue, sanSerif, serif } from "../../common/styles.js";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";

export const Header = styled.header`
  padding: 80px 24px 0;

  color: #fff;

  background: ${blue};
`;

export const HeaderContent = styled.div`
  position: relative;

  max-width: 600px;
  margin: 0 auto;
`;

export const HeaderImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

export const FaceGatsbyImage = styled(GatsbyImage)`
  filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.3));
  border-radius: 50%;
`;

export const Mike = styled.div`
  position: relative;
  padding: 2px 10px;

  color: #fff;
  font-family: ${serif};
  font-size: 36px;
  text-align: center;
`;

export const WhoAmI = styled.div`
  display: block;
  padding: 20px 10px 0;

  color: #fff;
  font-family: ${sanSerif};
  font-size: 18px;
  line-height: 1.75;
`;

export const Links = styled.div`
  padding: 80px 0 20px;
  text-align: center;
`;

export const LinkButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin: 18px 12px;

  color: inherit;
  font-size: 16px;
  text-decoration: underline;

  background: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  & > svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    flex: none;
  }
`;

export const LinkAnchor = LinkButton.withComponent("a");

export const HeaderGrid = styled.div`
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: auto fit-content(600px);
    justify-content: center;
    column-gap: 48px;

    ${HeaderImage} {
      margin-bottom: 0;
    }

    ${HeaderContent}: {
    }

    ${Links} {
      grid-column: span 2;
    }
  }
`;
