import styled from "styled-components";
import { siteWidth } from "../../common/styles.js";

export const Projects = styled.div`
  max-width: ${siteWidth}px;
  margin: auto;
  padding: 72px 12px 36px;
  box-sizing: border-box;
`;

export const Footer = styled.footer`
  max-width: ${siteWidth}px;
  margin: 80px auto;
  padding: 0 12px;

  font-size: 14px;
  line-height: 24px;
  text-align: center;

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
  }
`;
