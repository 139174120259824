import * as R from "ramda";
import { graphql, useStaticQuery } from "gatsby";

export default function useProjects() {
  const projectsData = useStaticQuery(query);

  const frontmatter = (key) => R.path(["frontmatter", key]);

  return R.compose(
    R.map(
      R.applySpec({
        date: frontmatter("date"),
        html: R.prop("html"),
        images: R.compose(
          R.reject(R.isNil),
          R.map(R.path(["childImageSharp", "fixed"])),
          R.defaultTo([]),
          frontmatter("images"),
        ),
        title: frontmatter("title"),
        technologies: frontmatter("technologies"),
        url: frontmatter("url"),
        // extract slug from filename
        // for more flexibility in the future, it could be added as frontmatter
        slug: R.compose(
          R.nth(1),
          // allow numbers to order the projects, eg, 2018-1-workshift.md
          R.match(/\d\d\d\d-(?:\d-)?(.*?).md/),
          R.last,
          R.split("/"),
          R.prop("fileAbsolutePath"),
        ),
      }),
    ),
    R.path(["allMarkdownRemark", "nodes"]),
  )(projectsData);
}

const query = graphql`
  query Projects {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: {
        order: [DESC, ASC]
        fields: [frontmatter___date, fileAbsolutePath]
      }
    ) {
      nodes {
        id
        fileAbsolutePath
        frontmatter {
          date
          title
          technologies
          url
          images {
            childImageSharp {
              fixed(width: 300, height: 300) {
                aspectRatio
                base64
                height
                originalName
                src
                srcSet
                srcSetWebp
                srcWebp
                width
              }
            }
          }
        }
        html
      }
    }
  }
`;
