import styled, { css } from "styled-components";
import { serif, sanSerif, bp, blue } from "../../common/styles.js";

export const Paper = styled.div`
  position: relative;
  padding: 30px 5px;
  max-width: 100%;
  width: 860px;
  box-sizing: content-box;

  font-size: 16px;
  line-height: 1.5;

  background: white;
  border: 1px solid black;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

  @media (min-width: ${bp.medium}px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: ${bp.large}px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export const Download = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 11px;
  color: #999;
  /* verdana is good for small font sizes */
  font-family: verdana, sans-serif;

  & a {
    padding: 0;
    color: ${blue};
  }
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  padding: 0;

  color: ${blue};

  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

export const Divider = styled.div`
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;

  &::after {
    content: "|";
  }
`;

export const Top = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const Contacts = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const Heading = styled.div`
  padding-left: 10px;
  padding-bottom: 5px;

  color: #22486a;
  font-size: 24px;
  font-family: ${serif};
  font-weight: bold;
`;

const indent = css`
  margin-left: 20px;

  @media (min-width: ${bp.medium}px) {
    margin-left: 40px;
  }
`;

export const Indent = styled.div`
  ${indent};
`;

export const Work = styled.div`
  margin-bottom: 20px;
  ${indent};
`;

export const Title = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: bold;
  font-family: ${sanSerif};
`;

export const DateRange = styled.div`
  float: right;
  margin-right: 10px;
  top: 0;
  font-size: 16px;
`;

export const Position = styled.div`
  color: #444;
  font-weight: normal;
  font-style: italic;
`;

export const Phone = styled.span`
  white-space: nowrap;
`;

export const Skills = styled.div`
  font-size: 18px;
`;

export const Skill = styled.span`
  display: inline-block; // prevent line breaks
`;

export const Sub = styled.span`
  color: #555;
  font-size: 16px;
  font-weight: normal;
`;

export const Ul = styled.ul`
  margin-top: 3px;
  margin-right: 30px;
  margin-bottom: 0;
  margin-left: 0;
  line-height: 1.33;

  @media (min-width: 600px) {
    margin-right: 50px;
  }

  li + li {
    margin-top: 8px;
  }
`;

export const LinkAnchor = styled.a`
  color: ${blue};

  &:visited {
    color: #693474;
  }
`;

export const Blurb = styled.div`
  text-align: left;
  margin-top: 16px;
  padding: 0 10px;
`;

export const Section = styled.div`
  border-top: 1px solid ${blue};
  margin-bottom: 30px;
  padding-top: 10px;
`;

export const Interests = styled(Section)`
  text-align: center;
`;
