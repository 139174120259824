import React from "react";
import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";
import * as S from "./Header.styles.js";

import File from "../Icons/File.jsx";
// import Github from "../Icons/Github.jsx";
import LinkedIn from "../Icons/LinkedIn.jsx";
import Mail from "../Icons/Mail.jsx";

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(absolutePath: { regex: "/green-helmet.jpg$/" }) {
        # file(absolutePath: { regex: "/mike.png$/" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            width
            srcWebp
            srcSetWebp
            srcSet
            src
            originalName
            height
            base64
            aspectRatio
          }
        }
      }
    }
  `);

  const face = R.path(["file", "childImageSharp", "fixed"], data);

  const years = new Date().getFullYear() - new Date("2007-09-07").getFullYear();

  return (
    <S.Header>
      <S.HeaderGrid>
        <S.HeaderImage>
          <S.FaceGatsbyImage fixed={face} alt="Mike" />
        </S.HeaderImage>
        <S.HeaderContent>
          <S.Mike>I'm Mike!</S.Mike>
          <S.WhoAmI>
            <p>
              I am a web developer with over {years} years of industry expertise
              building awesome web interactions using my passion for computer
              science, functional programming, data visualizations and user
              experience design.
            </p>
            <p>
              I also enjoy outdoor adventures including rock climbing, sailing,
              skiing, and scuba diving. When I'm not doing those activities, I
              keep busy with various web projects. Check them out below!
            </p>
          </S.WhoAmI>
        </S.HeaderContent>
        <S.Links>
          <S.LinkButton to={"#resume"}>
            <File /> Mike's Resume
          </S.LinkButton>
          <S.LinkAnchor href="mailto:michael.c.lambert@gmail.com">
            <Mail /> michael.c.lambert@gmail.com
          </S.LinkAnchor>
          <S.LinkAnchor
            href="https://www.linkedin.com/in/lax4mike/"
            target="_blank"
          >
            <LinkedIn /> linkedin.com/in/lax4mike
          </S.LinkAnchor>

          {/* <S.LinkAnchor href="https://github.com/lax4mike/" target="_blank">
          <Github /> https://github.com/lax4mike
        </S.LinkAnchor> */}
        </S.Links>
      </S.HeaderGrid>
    </S.Header>
  );
};

export default Header;
