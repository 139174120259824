import { useEffect, useRef } from "react";
import { createFocusTrap } from "focus-trap";

export default function useFocusTrap(props) {
  const {
    active,
    focusTrapOptions = {
      // clickOutsideDeactivates prevents FocusTrap from blocking handleClickAway
      clickOutsideDeactivates: true,
    },
    activateOptions,
    deactivateOptions,
    ref,
  } = props;

  const fallbackRef = useRef();
  const focusRef = ref || fallbackRef;

  const focusTrapInstanceRef = useRef();

  useEffect(() => {
    if (focusRef.current) {
      if (!focusTrapInstanceRef.current) {
        focusTrapInstanceRef.current = createFocusTrap(
          focusRef.current,
          focusTrapOptions,
        );
      }

      if (focusTrapInstanceRef.current) {
        if (active) {
          try {
            focusTrapInstanceRef.current.activate(activateOptions);
          } catch (e) {
            // don't show any error, because sometimes the focus trap won't have
            // any focusable items, and focus-trap throws in that case...
          }
        } else {
          focusTrapInstanceRef.current.deactivate(deactivateOptions);
        }
      }
    }
  }, [activateOptions, active, deactivateOptions, focusRef, focusTrapOptions]);

  return focusRef;
}
